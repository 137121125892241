import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { DEBUG } from '@glimmer/env';

export default class CloudVaultRadarRoute extends Route {
  @service api;
  @service userContext;
  @service currentUser;

  async model() {
    const { project, organization } = this.userContext;

    if (variation('hcp-vault-radar-public-beta')) {
      try {
        const tenantPromise = this.api.vaultRadar.tenant.createTenant(
          project.id,
          {
            location: {
              organizationId: organization.id,
            },
          }
        );

        const existingTenantPromise =
          this.api.vaultRadar.tenant.listOrganizationTenants(
            project.id,
            organization.id
          );

        const resolvedData = await Promise.all([
          tenantPromise,
          existingTenantPromise,
        ]);

        const [tenantData, existingTenantData] = resolvedData;
        const { provisioned_tenants } = existingTenantData;
        const castedTenantCount = Number.isFinite(parseInt(provisioned_tenants))
          ? parseInt(provisioned_tenants)
          : 0;

        const hasExistingTenant = castedTenantCount > 0;

        return { tenantData, hasExistingTenant };
      } catch (e) {
        if (DEBUG) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
  }
}
