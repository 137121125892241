export const VAULT_RADAR_TENANT_STATES = {
  ACTIVE_BETA: 'ACTIVE_BETA',
  ACTIVE_CUSTOMER: 'ACTIVE_CUSTOMER',
  NEW_BETA: 'NEW_BETA',
  NEW_CUSTOMER: 'NEW_CUSTOMER',
  MISSING_PERMISSIONS: 'MISSING_PERMISSIONS',
  CLAIMED_TENANT: 'CLAIMED_TENANT',
};

export const TENANT_TYPE = {
  BETA: 'BETA',
  INTERNAL: 'INTERNAL',
  CUSTOMER: 'CUSTOMER',
};

export const TENANT_STATE = {
  NEW: 'NEW',
  PROVISIONED: 'PROVISIONED',
};
