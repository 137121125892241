import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import IamPolicy from 'core/utils/iam-policy';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';
import {
  PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS,
  ACTION_UPDATE,
  ACTION_DELETE,
  ACTION_LIST,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';
import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import findRoleLocationsFromPolicies from 'hcp/utils/iam/find-role-locations-from-policies';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import { variation } from 'ember-launch-darkly';
import v2IamResource from 'core/utils/v2IamResource';
import { DEBUG } from '@glimmer/env';
import {
  VAULT_RADAR_AGENT_ROLE,
  VAULT_RADAR_CLI_USER_ROLE,
} from 'hcp/utils/constants';

export default class CloudAccessControlServicePrincipalsDetailRoute extends Route {
  @service api;
  @service abilities;
  @service userContext;

  async model({ principal_id: principalId }) {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { organization, project } = this.userContext;

    if (isProjectContext) {
      const [
        { servicePrincipal, keys: keysResponse },
        { policy: policyResponse },
      ] = await Promise.all([
        this.api.servicePrincipal.servicePrincipalsServiceGetProjectServicePrincipal(
          organization.id,
          project.id,
          principalId
        ),
        this.api.resourceManager.project.projectServiceGetIamPolicy(project.id),
      ]);

      const keys = keysResponse.filter((k) => k?.state !== 'DELETING'); // Optimistic update drops DELETING keys, avoids polling

      let workloadIdentityProviders = [];
      if (variation('hcp-ui-workload-identity-aws')) {
        const parentResourceName = `iam/project/${project.id}/service-principal/${servicePrincipal.name}`;
        ({ providers: workloadIdentityProviders } =
          await this.api.servicePrincipal.servicePrincipalsServiceListWorkloadIdentityProvider(
            parentResourceName
          ));
        let providerUris = {
          azure: 'sts.windows.net',
          gcp: 'accounts.google.com',
          gitlab: 'gitlab.com',
          github: 'token.actions.githubusercontent.com',
        };

        //get wipDisplayName using V2IamResource util and HDS icon name
        workloadIdentityProviders.map((wip) => {
          const v2Resource = new v2IamResource(wip.resourceName);
          wip['displayName'] = v2Resource.wipDisplayName;
          if (wip?.awsConfig?.accountId) {
            wip['providerIconName'] = 'aws';
          }

          if (wip?.oidcConfig?.issuerUri) {
            let splitConfigIssuerUri = wip.oidcConfig.issuerUri.split('/');
            for (const [key, value] of Object.entries(providerUris)) {
              if (splitConfigIssuerUri[2] === value) {
                wip['providerIconName'] = key;
              }
            }
          }
        });
      }
      const policy = new IamPolicy(policyResponse);
      const role = policy.getMemberById(principalId)?.roleId;

      let projectRoles = [];

      if (
        this.abilities.can('list roles') &&
        variation('hcp-ui-fine-grained-roles')
      ) {
        const resourceName = `project/${project.id}`;
        const fetchAllRoles = fetchUntilEmpty(
          (...args) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args
            ),
          'roles'
        );
        ({ roles: projectRoles } = await fetchAllRoles(
          resourceName,
          undefined,
          100
        ));

        const allowListedRoles = [VAULT_RADAR_CLI_USER_ROLE];
        if (variation('hcp-vault-radar-user-interface-show-station')) {
          allowListedRoles.push(VAULT_RADAR_AGENT_ROLE);
        }
        const denyListedRoles = (
          variation('hcp-ui-fine-grained-blacklisted-roles') ?? []
        ).filter((roleId) => {
          return !allowListedRoles.includes(roleId);
        });

        projectRoles = filterBlacklistedRoles(projectRoles, denyListedRoles);
      }
      let workloadIdentityProvidersAllowedPermissions = [];
      // Fetch current user's workload-identity-providers for the resource
      try {
        ({ allowedPermissions: workloadIdentityProvidersAllowedPermissions } =
          await this.api.resourceManager.project.projectServiceTestIamPermissions(
            project.id,
            {
              permissions: [
                `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_LIST}`,
                `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_UPDATE}`,
                `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_DELETE}`,
                `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_CREATE}`,
              ],
            }
          ));
      } catch (e) {
        if (DEBUG) {
          console.error(e);
        }
      }
      return {
        isProjectContext,
        keys,
        organization,
        policy,
        project,
        projectRoles,
        rawPolicy: policyResponse,
        resourceType: TYPE_PROJECT,
        role,
        roleLocations: findRoleLocationsFromPolicies({
          principalIds: [servicePrincipal.id],
          roles: projectRoles,
          policies: [
            {
              link: { type: TYPE_PROJECT, id: project.id, name: project.name },
              policy: policyResponse,
            },
          ],
        }),
        servicePrincipal,
        workloadIdentityProviders,
        workloadIdentityProvidersAllowedPermissions,
      };
    } else {
      const [
        { servicePrincipal, keys: keysResponse },
        { policy: policyResponse },
      ] = await Promise.all([
        this.api.servicePrincipal.servicePrincipalsServiceGetOrganizationServicePrincipal(
          organization.id,
          principalId
        ),
        this.api.resourceManager.org.organizationServiceGetIamPolicy(
          organization.id
        ),
      ]);

      const keys = keysResponse.filter((k) => k?.state !== 'DELETING'); // Optimistic update drops DELETING keys, avoids polling
      const policy = new IamPolicy(policyResponse);
      const role = policy.getMemberById(principalId)?.roleId;

      return {
        isProjectContext,
        keys,
        organization,
        policy,
        resourceType: TYPE_ORGANIZATION,
        role,
        servicePrincipal,
      };
    }
  }
}
