/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';
import { DEBUG } from '@glimmer/env';

/**
 *
 * `PageInvitesList` lists the HCP Invites.
 *
 *
 * ```
 * <Page::Invites::List @model={{model}} />
 * ```
 *
 * @class PageInvitesList
 *
 */

/**
 * `model` has the list of HCP organization invites and inviters.
 * @argument model
 * @type {Object}
 */
export default class PageInvitesListComponent extends Component {
  @service api;
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showRevokeModal = false;
  @tracked inviterMap = {};
  @tracked invitesToRevoke = null;
  @tracked showResendModal = false;
  @tracked inviteToResend = null;

  @task
  *resendInvite(evt) {
    evt.preventDefault();

    const { deleted } = yield* this.deleteInvite();
    const { created } = deleted ? yield* this.createInvite() : {};

    created ? this.handleSuccess() : this.handleError();
  }

  *deleteInvite() {
    try {
      yield this.api.invitation.invitationsServiceDeleteOrganizationInvitation(
        this.inviteToResend.organizationId,
        this.inviteToResend.id
      );
      return {
        deleted: true,
      };
    } catch (e) {
      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return {
        deleted: false,
      };
    }
  }

  *createInvite() {
    try {
      yield this.api.invitation.invitationsServiceCreateOrganizationInvitations(
        this.inviteToResend.organizationId,
        {
          invitations: [
            {
              inviteeEmail: this.inviteToResend.inviteeEmail,
              role: this.inviteToResend.role,
            },
          ],
        }
      );
      return {
        created: true,
      };
    } catch (e) {
      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return {
        created: false,
      };
    }
  }

  @action
  buildInviterMap() {
    let { userPrincipals = [] } = this.args.model;

    this.inviterMap = userPrincipals.reduce((map, user) => {
      map[user.id] = user;
      return map;
    }, {});

    return this;
  }

  @action
  confirmRevokeInvites(invites) {
    this.showRevokeModal = true;
    this.invitesToRevoke = invites;
  }

  @action
  confirmResendInvite(invite) {
    this.showResendModal = true;
    this.inviteToResend = invite[0];
  }

  @action
  onRevokeInvitesSuccess({ invites }) {
    this.resetRevokeInvites();

    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);

    this.flashMessages.success(
      this.intl.t(`iam.invitations.modals.revoke.success-title`),
      {
        content: this.intl.t(`iam.invitations.modals.revoke.success-body`, {
          user: invites[0].inviteeEmail,
          htmlSafe: true,
        }),
      }
    );
    return route.refresh();
  }

  @action
  handleError() {
    this.flashMessages.error(
      this.intl.t('iam.invitations.modals.resend.error.title'),
      {
        content: this.intl.t('iam.invitations.modals.resend.error.body', {
          inviteeEmail: this.inviteToResend.inviteeEmail,
          htmlSafe: true,
        }),
      }
    );
    this.resetResendInvite();
  }

  @action
  handleSuccess() {
    this.flashMessages.success(
      this.intl.t('iam.invitations.modals.resend.success.title'),
      {
        content: this.intl.t('iam.invitations.modals.resend.success.body', {
          inviteeEmail: this.inviteToResend.inviteeEmail,
          htmlSafe: true,
        }),
      }
    );
    this.resetResendInvite();
    const route = getOwner(this).lookup(
      `route:${this.router.currentRouteName}`
    );
    if (route) {
      return route.refresh();
    }
  }

  @action
  resetResendInvite() {
    this.showResendModal = false;
    this.inviteToResend = null;
  }

  @action
  resetRevokeInvites() {
    this.showRevokeModal = false;
    this.invitesToRevoke = null;
  }
}
