import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import config from 'hcp/config/environment';

export default class CallbackRoute extends Route {
  @service config;
  @service router;
  @service session;

  queryParams = {
    code: {
      replace: true,
    },
    state: {
      replace: true,
    },
    error: {
      replace: true,
    },
    product: {
      replace: true,
    },
  };

  async beforeModel() {
    let { code, state, error } = this.paramsFor(this.routeName);

    this.session.prohibitAuthentication(config.APP.homeRoute);

    if (error) {
      return this.router.transitionTo(config.APP.loginRoute, {
        queryParams: { error },
      });
    }
    // finally if there's no error and we're not logged in, we want to authenticate our session
    try {
      await this.session.authenticate(
        `authenticator:${this.config.authenticatorType}`,
        {
          isCallback: true,
        }
      );
    } catch (e) {
      Sentry.captureException(e);
    }

    if (!code || !state || !this.session.isAuthenticated) {
      return this.router.transitionTo(config.APP.loginRoute);
    }
  }

  resetController(controller) {
    controller.error = '';
    controller.code = '';
    controller.state = '';
  }
}
