import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import IamPolicy from 'core/utils/iam-policy';
import { variation } from 'ember-launch-darkly';
import PolicyManager from 'manage-access/utils/policy-manager';
import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import findRoleLocationsFromPolicies from 'hcp/utils/iam/find-role-locations-from-policies';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import {
  VAULT_RADAR_AGENT_ROLE,
  VAULT_RADAR_CLI_USER_ROLE,
} from 'hcp/utils/constants';

export default class CloudAccessControlServicePrincipalsListRoute extends Route {
  @service api;
  @service abilities;
  @service userContext;

  async model() {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { organization, project } = this.userContext;

    if (isProjectContext) {
      let [{ servicePrincipals }, { policy: policyResponse }] =
        await Promise.all([
          this.api.servicePrincipal.servicePrincipalsServiceListProjectServicePrincipals(
            organization.id,
            project.id
          ),
          this.api.resourceManager.project.projectServiceGetIamPolicy(
            project.id
          ),
        ]);

      const policy = new IamPolicy(policyResponse);
      let servicePrincipalToRoleBindings = servicePrincipals.map((sp) => {
        return {
          servicePrincipal: sp,
          role: policy.getMemberById(sp.id)?.roleId,
        };
      });
      if (variation('hcp-ui-fine-grained-roles')) {
        // Get all the roles (org and project level) and attach them to their service principal objects
        let projectRoles = [];
        if (this.abilities.can('list roles')) {
          const resourceName = `project/${project.id}`;
          const fetchAllRoles = fetchUntilEmpty(
            (...args) =>
              this.api.resourceManager.resources.resourceServiceListRoles(
                ...args
              ),
            'roles'
          );
          ({ roles: projectRoles } = await fetchAllRoles(
            resourceName,
            undefined,
            100
          ));

          const allowListedRoles = [VAULT_RADAR_CLI_USER_ROLE];
          if (variation('hcp-vault-radar-user-interface-show-station')) {
            allowListedRoles.push(VAULT_RADAR_AGENT_ROLE);
          }
          const denyListedRoles = (
            variation('hcp-ui-fine-grained-blacklisted-roles') ?? []
          ).filter((roleId) => {
            return !allowListedRoles.includes(roleId);
          });

          projectRoles = filterBlacklistedRoles(projectRoles, denyListedRoles);
        }

        // Get policies
        let policyLocations = [];
        const ProjectPolicy = new PolicyManager({
          policy: policyResponse,
        });
        if (project && ProjectPolicy) {
          policyLocations.push({
            link: {
              id: project.id,
              name: project.name,
              type: 'hashicorp.resource-manager.project',
            },
            policy: policyResponse,
          });

          // Map the service principal bindings with role locations
          const servicePrincipalIds = servicePrincipals.map((sp) => sp.id);
          let roleLocations = findRoleLocationsFromPolicies({
            principalIds: servicePrincipalIds,
            roles: projectRoles,
            policies: policyLocations,
          });
          servicePrincipalToRoleBindings = servicePrincipalToRoleBindings.map(
            (binding) => {
              let filteredRoles = roleLocations.filter((roleLocation) => {
                return (
                  roleLocation.member.memberId === binding.servicePrincipal.id
                );
              });
              return {
                ...binding.servicePrincipal,
                roleLocations: filteredRoles,
              };
            }
          );
        }
        return {
          organization,
          project,
          servicePrincipals,
          servicePrincipalToRoleBindings,
          policyLocations,
          isProjectContext,
        };
      }

      return {
        organization,
        project,
        servicePrincipals,
        servicePrincipalToRoleBindings,
        policy,
        isProjectContext,
      };
    } else {
      // only fetch org-level principals and policy if not in project context
      let [{ servicePrincipals }, { policy: policyResponse }] =
        await Promise.all([
          this.api.servicePrincipal.servicePrincipalsServiceListOrganizationServicePrincipals(
            organization.id
          ),
          this.api.resourceManager.org.organizationServiceGetIamPolicy(
            organization.id
          ),
        ]);

      const policy = new IamPolicy(policyResponse);
      const servicePrincipalToRoleBindings = servicePrincipals.map((sp) => {
        return {
          servicePrincipal: sp,
          role: policy.getMemberById(sp.id)?.roleId,
        };
      });

      return {
        organization,
        servicePrincipals,
        servicePrincipalToRoleBindings,
        policy,
        isProjectContext,
      };
    }
  }
}
