import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import IamPolicy from 'core/utils/iam-policy';
import { DEBUG } from '@glimmer/env';
import { CollapseResourceName } from 'core/utils/resource-name';

import decorateMembersWithIsManager from 'hcp/utils/group-members-is-manager-role';
import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import findRoleLocationsFromPolicies from 'hcp/utils/iam/find-role-locations-from-policies';
import { TYPE_PROJECT } from 'common/utils/cloud-resource-types';
import { VAULT_RADAR_DEVELOPER_ROLE } from 'hcp/utils/constants';

export default class CloudAccessControlGroupsDetailRoute extends Route {
  @service router;
  @service api;
  @service abilities;
  @service userContext;

  serialize(_, params) {
    // NOTE: We're decoding the v2 `resource_name`, not serializing it ... that's OK.
    return {
      ...params,
      resource_name: decodeURIComponent(params.resource_name),
    };
  }

  async model(params) {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { project, organization } = this.userContext;
    const { resource_name: resourceName } = params;
    let scimEnabled = false;

    if (variation('hcp-identity-scim-enabled')) {
      try {
        ({ isEnabled: scimEnabled } =
          await this.api.scim.scimServiceIsScimEnabled(organization.id));
      } catch (e) {
        if (DEBUG) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    const { group } = await this.api.groups.groupsServiceGetGroup(resourceName);
    let members = [];
    let groupLevelPermissions = [];

    try {
      ({ allowedPermissions: groupLevelPermissions } =
        await this.api.resourceManager.authorization.authorizationServiceTestIamPermissions(
          {
            permissions: [
              'iam.groups.update',
              'iam.groups.update-members',
              'iam.groups.list-members',
            ],
            resourceName,
          }
        ));
    } catch (e) {
      if (DEBUG) {
        console.error(e);
      }
    }

    if (
      this.abilities.can('list members in group', group, {
        groupLevelPermissions,
      })
    ) {
      ({ members } =
        await this.api.groups.groupsServiceListGroupMembers(resourceName));
    }

    // We are only checking the group policy for member roles,
    // so if there are no members don't make this call
    if (members.length) {
      try {
        const { policy } =
          await this.api.resourceManager.resources.resourceServiceGetIamPolicy(
            resourceName
          );
        members = decorateMembersWithIsManager(members, policy);
      } catch (e) {
        //eslint-disable-next-line no-console
        if (DEBUG) {
          console.error(e);
        }
      }
    }

    if (isProjectContext) {
      let projectRoles = [];
      if (
        this.abilities.can('list roles') &&
        variation('hcp-ui-fine-grained-roles')
      ) {
        const resourceName = `project/${project.id}`;
        const fetchAllRoles = fetchUntilEmpty(
          (...args) =>
            this.api.resourceManager.resources.resourceServiceListRoles(
              ...args
            ),
          'roles'
        );
        ({ roles: projectRoles } = await fetchAllRoles(
          resourceName,
          undefined,
          100
        ));

        const allowListedRoles = [];
        if (variation('hcp-vault-radar-abac-workflow')) {
          allowListedRoles.push(VAULT_RADAR_DEVELOPER_ROLE);
        }
        const denyListedRoles = (
          variation('hcp-ui-fine-grained-blacklisted-roles') ?? []
        ).filter((roleId) => {
          return !allowListedRoles.includes(roleId);
        });

        projectRoles = filterBlacklistedRoles(projectRoles, denyListedRoles);
      }
      const { policy: projectIamPolicyRaw } =
        await this.api.resourceManager.project.projectServiceGetIamPolicy(
          project.id
        );
      const projectIamPolicy = new IamPolicy(projectIamPolicyRaw);

      const role = await projectIamPolicy.getMemberById(group.resourceId)
        ?.roleId;

      return {
        group,
        groupLevelPermissions,
        isProjectContext,
        members,
        project,
        role,
        scimEnabled,
        truncatedResourceName: CollapseResourceName(
          group.resourceName,
          `iam/organization/${organization.id}/group/`,
          true
        ),
        roleLocations: findRoleLocationsFromPolicies({
          principalIds: [group.resourceId],
          roles: projectRoles,
          policies: [
            {
              link: { type: TYPE_PROJECT, id: project.id, name: project.name },
              policy: projectIamPolicyRaw,
            },
          ],
        }),
      };
    } else {
      let resourcePolicy;
      try {
        ({ policy: resourcePolicy } =
          await this.api.resourceManager.resources.resourceServiceGetIamPolicy(
            group.resourceName
          ));
      } catch (e) {
        if (DEBUG) {
          console.error(e);
        }
        // this is expected on a policy that never has been set, create new empty policy
        if (e.status === 404) {
          // create new empty policy without any managers assigned
          resourcePolicy = {
            bindings: [
              {
                roleId: 'roles/iam.group-manager',
                members: [],
              },
            ],
            etag: '',
          };
        }
      }

      let eligibleMembers = [];

      const filter = {
        organizationId: organization.id,
        searchText: '',
        principalTypes: ['PRINCIPAL_TYPE_USER'],
        excludeMembersOfGroupNames: [resourceName],
      };

      if (
        this.abilities.can('update group', group, { groupLevelPermissions })
      ) {
        const { principals } = await this.api.iam.iamServiceSearchPrincipals(
          organization.id,
          { filter }
        );
        eligibleMembers = principals;
      }

      return {
        eligibleMembers,
        group,
        isProjectContext,
        members,
        organization,
        scimEnabled,
        groupLevelPermissions: groupLevelPermissions,
        rawGroupPolicy: resourcePolicy,
        truncatedResourceName: CollapseResourceName(
          group.resourceName,
          `iam/organization/${organization.id}/group/`,
          true
        ),
      };
    }
  }
}
