import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import { DEBUG } from '@glimmer/env';
import IamPolicy from 'core/utils/iam-policy';
import PolicyManager from 'manage-access/utils/policy-manager';

import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import findRoleLocationsFromPolicies from 'hcp/utils/iam/find-role-locations-from-policies';

import PaginatedRoute from 'core/routes/paginated-route';
import { VAULT_RADAR_DEVELOPER_ROLE } from 'hcp/utils/constants';

export default class CloudAccessControlGroupsListRoute extends PaginatedRoute {
  @service api;
  @service abilities;
  @service router;
  @service userContext;

  async beforeModel() {
    const { isProjectContext } = this.modelFor('cloud.access-control');

    if (isProjectContext) {
      // The project-level route only gets the IAM policy.
      if (this.abilities.cannot('get-iam-policy in project')) {
        const error = new Error('ForbiddenError');
        error.name = 'ForbiddenError';
        error.status = 403;
        throw error;
      }
    }
  }

  serialize(_, params) {
    // NOTE: We're decoding the v2 `resource_name`, not serializing it ... that's OK.
    return {
      ...params,
      resource_name: decodeURIComponent(params.resource_name),
    };
  }

  async model({ pageSize, nextPageToken, previousPageToken }) {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { organization, project } = this.userContext;

    const parentResourceName = `organization/${organization.id}`;
    const { groups, pagination } =
      await this.api.groups.groupsServiceListGroups(
        parentResourceName,
        undefined, // filter.member_principal_id
        undefined, // filter.group_resource_ids
        pageSize, // Default is 30 https://github.com/hashicorp/cloud-iam/blob/main/internal/repository/groups.go#L44
        nextPageToken,
        previousPageToken
      );
    let scimEnabled = false;
    if (variation('hcp-identity-scim-enabled')) {
      try {
        ({ isEnabled: scimEnabled } =
          await this.api.scim.scimServiceIsScimEnabled(organization.id));
      } catch (e) {
        if (DEBUG) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    let hasGroupsToAdd;
    let projectGroupsToMembersListBindings;

    if (isProjectContext) {
      const { policy: projectIamPolicyRaw } =
        await this.api.resourceManager.project.projectServiceGetIamPolicy(
          project.id
        );
      if (variation('hcp-ui-fine-grained-roles')) {
        // Get policies
        let policies = [];
        let resourcePermissions = [];

        const ProjectIamPolicy = new PolicyManager({
          policy: projectIamPolicyRaw,
        });

        if (project && ProjectIamPolicy) {
          policies.push({
            link: {
              id: project.id,
              name: project.name,
              type: 'hashicorp.resource-manager.project',
            },
            policy: projectIamPolicyRaw,
          });
        }

        // Get applicable groups in the project.
        const applicableGroups = groups.filter(
          (g) => !!ProjectIamPolicy.getRolesByMemberId(g.resourceId)
        );

        // Fetch batch list-members permissions for the applicable groups.
        try {
          ({ resourcePermissions } =
            await this.api.resourceManager.authorization.authorizationServiceBatchTestIamPermissions(
              {
                resources: applicableGroups.map((group) => {
                  return {
                    permissions: ['iam.groups.list-members'],
                    resourceName: group.resourceName,
                  };
                }),
              }
            ));
        } catch (e) {
          if (DEBUG) {
            console.error(e);
          }
        }

        const pendingProjectGroupsToMembersListBindings = applicableGroups.map(
          async (group) => {
            let members = [];

            if (
              this.abilities.can('list members in group', group, {
                groupLevelPermissions:
                  resourcePermissions[group.resourceName]?.allowedPermissions,
              })
            ) {
              ({ members } =
                await this.api.groups.groupsServiceListGroupMembers(
                  group.resourceName
                ));
            }

            const { roleIds } = await ProjectIamPolicy.getRolesByMemberId(
              group.resourceId
            );
            return { group, members, roleIds };
          }
        );
        projectGroupsToMembersListBindings = await Promise.all(
          pendingProjectGroupsToMembersListBindings
        );

        // Get groups that haven't been added to the project
        hasGroupsToAdd = !!groups.filter(
          (g) => !ProjectIamPolicy.getRolesByMemberId(g.resourceId)?.roleIds
        ).length;

        // Get all the roles (project level) and attach them to their group objects
        let projectRoles = [];
        if (this.abilities.can('list roles')) {
          const resourceName = `project/${project.id}`;
          const fetchAllRoles = fetchUntilEmpty(
            (...args) =>
              this.api.resourceManager.resources.resourceServiceListRoles(
                ...args
              ),
            'roles'
          );
          ({ roles: projectRoles } = await fetchAllRoles(
            resourceName,
            undefined,
            100
          ));

          const allowListedRoles = [];
          if (variation('hcp-vault-radar-abac-workflow')) {
            allowListedRoles.push(VAULT_RADAR_DEVELOPER_ROLE);
          }
          const denyListedRoles = (
            variation('hcp-ui-fine-grained-blacklisted-roles') ?? []
          ).filter((roleId) => {
            return !allowListedRoles.includes(roleId);
          });

          projectRoles = filterBlacklistedRoles(projectRoles, denyListedRoles);
        }

        const groupIds = groups.map((g) => g.resourceId);
        const roleLocations = findRoleLocationsFromPolicies({
          principalIds: groupIds,
          groups,
          roles: projectRoles,
          policies,
        });
        projectGroupsToMembersListBindings =
          projectGroupsToMembersListBindings.map((binding) => {
            let filteredRoles = roleLocations.filter((roleLocation) => {
              return roleLocation.member.memberId === binding.group.resourceId;
            });
            return {
              ...binding.group,
              roleLocations: filteredRoles,
            };
          });
      } else {
        // If rbac FF is off, fetch roles the old way (using IamPolicy) so we only get one org role per group
        const projectIamPolicy = new IamPolicy(projectIamPolicyRaw);
        const applicableGroups = groups.filter(
          (g) => !!projectIamPolicy.getMemberById(g.resourceId, 'GROUP')?.roleId
        );
        let resourcePermissions = [];

        // Fetch batch list-members permissions for the applicable groups.
        try {
          ({ resourcePermissions } =
            await this.api.resourceManager.authorization.authorizationServiceBatchTestIamPermissions(
              {
                resources: applicableGroups.map((group) => {
                  return {
                    permissions: ['iam.groups.list-members'],
                    resourceName: group.resourceName,
                  };
                }),
              }
            ));
        } catch (e) {
          if (DEBUG) {
            console.error(e);
          }
        }

        const pendingProjectGroupsToMembersListBindings = applicableGroups.map(
          async (group) => {
            let members = [];

            if (
              this.abilities.can('list members in group', group, {
                groupLevelPermissions:
                  resourcePermissions[group.resourceName]?.allowedPermissions,
              })
            ) {
              ({ members } =
                await this.api.groups.groupsServiceListGroupMembers(
                  group.resourceName
                ));
            }
            const role = await projectIamPolicy.getMemberById(group.resourceId)
              ?.roleId;
            return { group, members, role };
          }
        );

        projectGroupsToMembersListBindings = await Promise.all(
          pendingProjectGroupsToMembersListBindings
        );
        hasGroupsToAdd = !!groups.filter(
          (g) => !projectIamPolicy.getMemberById(g.resourceId, 'GROUP')?.roleId
        ).length;
      }

      return {
        isProjectContext,
        project,
        projectGroupsToMembersListBindings,
        hasGroupsToAdd,
      };
    } else {
      let groupPermissionsAllGroups;
      // array of all permissions requested for all groups
      let requestedPermissions = [];

      // Iterate through each group to construct testIamPermissions payload
      for (const group of groups) {
        requestedPermissions.push({
          permissions: [
            'iam.groups.update',
            'iam.groups.update-members',
            'iam.groups.list-members',
          ],
          resourceName: group.resourceName,
        });
      }
      try {
        ({ resourcePermissions: groupPermissionsAllGroups } =
          await this.api.resourceManager.authorization.authorizationServiceBatchTestIamPermissions(
            {
              resources: requestedPermissions,
            }
          ));
      } catch (e) {
        if (DEBUG) {
          console.error(e);
        }
      }

      return {
        isProjectContext,
        groups,
        groupPermissionsAllGroups,
        pagination,
        scimEnabled,
      };
    }
  }
}
